import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A calendar date that does include a time component. */
  DateTime: any;
  /** A 128-bit, floating point value that offers greater local precision, with a smaller range, than other floating-point types. (Min: -79228162514264337593543950335, Max: 79228162514264337593543950335) */
  Decimal: any;
  /** A 64-bit, floating-point value. (Min: -1.7976931348623157E+308, Max: 1.7976931348623157E+308) */
  Double: any;
  /** A standard guid (e.g. '6dd43342-ffe6-4964-bb6f-e31c8e50ec86'). */
  Guid: any;
};

export class AccessVm {
  numberClick: Scalars['Int'];
  payments: Scalars['Int'];
  register: Scalars['Int'];
  site: Scalars['Int'];
};

export class AccountVm {
  account?: Maybe<Scalars['String']>;
  accountDigit?: Maybe<Scalars['String']>;
  agencyNumber?: Maybe<Scalars['String']>;
  bank?: Maybe<BankVm>;
  createdAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  type?: Maybe<Scalars['String']>;
};

export class AddressComponentVm {
  long_name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export class Affiliate {
  affiliateAccess?: Maybe<Array<Maybe<AffiliateAccess>>>;
  affiliateAddresses?: Maybe<Array<Maybe<AffiliateAddress>>>;
  affiliateClick?: Maybe<Array<Maybe<AffiliateClick>>>;
  affiliateDigitalAccount?: Maybe<AffiliateDigitalAccount>;
  affiliateHitorySales?: Maybe<Array<Maybe<AffiliateHitorySales>>>;
  affiliateTransactionSupers?: Maybe<Array<Maybe<AffiliateTransactionSuper>>>;
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  ipOrigin?: Maybe<Scalars['String']>;
  isAcceptedTerms?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  isDigitalAccount?: Maybe<Scalars['String']>;
  isValidated?: Maybe<Scalars['Boolean']>;
  iuguToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  percentMetrics?: Maybe<PercentMetrics>;
  percentMetricsId?: Maybe<Scalars['Guid']>;
  phone?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  resetPasswordToken?: Maybe<Scalars['Guid']>;
  smsCode?: Maybe<Scalars['String']>;
  socialFacebook?: Maybe<Scalars['String']>;
  socialInstagram?: Maybe<Scalars['String']>;
  socialOthers?: Maybe<Scalars['String']>;
  socialTikTok?: Maybe<Scalars['String']>;
  socialYoutube?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Store>>>;
  transactionAffiliates?: Maybe<Array<Maybe<TransactionAffiliate>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validateToken?: Maybe<Scalars['Guid']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export class AffiliateAccess {
  affiliate?: Maybe<Affiliate>;
  affiliateId: Scalars['Guid'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isDeleted: Scalars['Boolean'];
  trackId?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export class AffiliateAddress {
  affiliate?: Maybe<Affiliate>;
  affiliateId: Scalars['Guid'];
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDeleted: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  referencePoint?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class AffiliateClick {
  affiliate?: Maybe<Affiliate>;
  affiliateId: Scalars['Guid'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isDeleted: Scalars['Boolean'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export class AffiliateConsolidatedGeneralVm {
  consolidatedGeneralInfos?: Maybe<Array<Maybe<ConsolidatedGeneralInfoVm>>>;
  type?: Maybe<Scalars['String']>;
};

export class AffiliateConsolidatedRegionsInformationVm {
  consolidatedRegionsInfos?: Maybe<Array<Maybe<ConsolidatedRegionsInfoVm>>>;
  percentagePresent: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export class AffiliateDigitalAccount {
  affiliate?: Maybe<Affiliate>;
  affiliateDigitalAccountBanks?: Maybe<Array<Maybe<AffiliateDigitalAccountBank>>>;
  affiliateId?: Maybe<Scalars['Guid']>;
  billet?: Maybe<Scalars['Boolean']>;
  credit?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  documentPhoto?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  income?: Maybe<Scalars['Decimal']>;
  installmentLimit?: Maybe<Scalars['Int']>;
  isPolitical?: Maybe<Scalars['Boolean']>;
  iuguAccountId?: Maybe<Scalars['String']>;
  iuguApiTestToken?: Maybe<Scalars['String']>;
  iuguApiToken?: Maybe<Scalars['String']>;
  iuguName?: Maybe<Scalars['String']>;
  iuguUserToken?: Maybe<Scalars['String']>;
  motherName?: Maybe<Scalars['String']>;
  pix?: Maybe<Scalars['Boolean']>;
  selfieDocument?: Maybe<Scalars['String']>;
  selfiePhoto?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export class AffiliateDigitalAccountBank {
  account?: Maybe<Scalars['String']>;
  accountDigit?: Maybe<Scalars['String']>;
  affiliateAccount?: Maybe<AffiliateDigitalAccount>;
  agencyNumber?: Maybe<Scalars['String']>;
  bank?: Maybe<Bank>;
  bankId: Scalars['Guid'];
  createdAt: Scalars['DateTime'];
  digitalAccountId?: Maybe<Scalars['Guid']>;
  document?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  type?: Maybe<Scalars['String']>;
};

export class AffiliateDigitalAccountBanksVm {
  account?: Maybe<Scalars['String']>;
  accountDigit?: Maybe<Scalars['String']>;
  agencyNumber?: Maybe<Scalars['String']>;
  bank?: Maybe<BankVm>;
  bankId: Scalars['Guid'];
  createdAt: Scalars['DateTime'];
  digitalAccountId?: Maybe<Scalars['Guid']>;
  document?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  type?: Maybe<Scalars['String']>;
};

export class AffiliateExtractsTypesVm {
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
};

export class AffiliateHitorySales {
  affiliateId?: Maybe<Scalars['Guid']>;
  affiliates?: Maybe<Affiliate>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isDeleted: Scalars['Boolean'];
  percentMetrics?: Maybe<PercentMetrics>;
  percentMetricsId?: Maybe<Scalars['Guid']>;
  status?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['Guid']>;
  transaction?: Maybe<Transaction>;
  transactionAt?: Maybe<Scalars['DateTime']>;
  transactionId?: Maybe<Scalars['Guid']>;
  typePayment?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class AffiliateTransactionSuper {
  affiliate?: Maybe<Affiliate>;
  affiliateId?: Maybe<Scalars['Guid']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Guid'];
  isAccounted: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  paymentAt: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  transaction?: Maybe<Transaction>;
  transactionId?: Maybe<Scalars['Guid']>;
  typePayment?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class AffiliateVm {
  alias?: Maybe<Scalars['String']>;
  automaticRechargeValue?: Maybe<Scalars['Decimal']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDigitalAccount?: Maybe<Scalars['String']>;
  isOwner: Scalars['Boolean'];
  isValidated: Scalars['Boolean'];
  iugutoken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pathImage?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountry?: Maybe<Scalars['String']>;
  planRecharge?: Maybe<PlanRechargeVm>;
  planRechargeId?: Maybe<Scalars['Guid']>;
  salesIntention?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  typePayment?: Maybe<Scalars['String']>;
  typeRecharge?: Maybe<Scalars['String']>;
  userAddresses?: Maybe<Array<Maybe<AffiliateAddress>>>;
  userCreditCardId?: Maybe<Scalars['Guid']>;
};

export class AutoCompleteResponsePredictionsVm {
  description?: Maybe<Scalars['String']>;
  matched_substrings?: Maybe<Array<Maybe<MatchedSubstringVm>>>;
  place_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  structured_formatting?: Maybe<StructuredFormattingVm>;
  terms?: Maybe<Array<Maybe<TermVm>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export class AutoCompleteResponseVm {
  predictions?: Maybe<Array<Maybe<AutoCompleteResponsePredictionsVm>>>;
};

export class Bank {
  affiliateDigitalAccountBanks?: Maybe<Array<Maybe<AffiliateDigitalAccountBank>>>;
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  maskAccount?: Maybe<Scalars['String']>;
  maskAgency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class BankVm {
  bankName?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  maskAccount?: Maybe<Scalars['String']>;
  maskAgency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export class CommissionAffiliationVm {
  active: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  percent: Scalars['Double'];
};

export class ConsolidatedGeneralInfoVm {
  percentage: Scalars['Int'];
  position: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export class ConsolidatedRegionsInfoVm {
  percentage: Scalars['Int'];
  position: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export class DefaultMessageVm {
  code: Scalars['Int'];
  id?: Maybe<Scalars['Guid']>;
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export class DigitalAccountBalanceVm {
  balance?: Maybe<Scalars['Decimal']>;
  balanceAvailable?: Maybe<Scalars['Decimal']>;
  digitalAccountId: Scalars['Guid'];
  id: Scalars['Guid'];
};

export class DigitalAccountVm {
  affiliate?: Maybe<AffiliateVm>;
  affiliateDigitalAccountBalance?: Maybe<DigitalAccountBalanceVm>;
  affiliateDigitalAccountBanks?: Maybe<Array<Maybe<AffiliateDigitalAccountBanksVm>>>;
  document?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  income: Scalars['Decimal'];
  isPolitical: Scalars['Boolean'];
  motherName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export class ExtractInfoVm {
  hour?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value: Scalars['Decimal'];
};

export class ExtractVm {
  extractInfos?: Maybe<Array<Maybe<ExtractInfoVm>>>;
  label?: Maybe<Scalars['String']>;
};

export class GeometryVm {
  location?: Maybe<Location>;
  viewport?: Maybe<ViewportVm>;
};

export class Input_AccessRequestVm {
  alias?: InputMaybe<Scalars['String']>;
  trackId?: InputMaybe<Scalars['String']>;
};

export class Input_AddressVm {
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export class Input_AuthenticationVm {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export class Input_ChangePasswordVm {
  actualPassword?: InputMaybe<Scalars['String']>;
  confirmNewPassword?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
};

export class Input_CreateDigitalAccountVm {
  digitalAccountBank?: InputMaybe<Input_CreateUserDigitalAccountBanksVm>;
  document?: InputMaybe<Scalars['String']>;
  income?: Scalars['Decimal'];
  isPolitical?: Scalars['Boolean'];
  motherName?: InputMaybe<Scalars['String']>;
};

export class Input_CreateRechargeVm {
  alias?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<Scalars['String']>;
  planSuperId?: InputMaybe<Scalars['Guid']>;
  storeId?: InputMaybe<Scalars['Guid']>;
  typePayment?: InputMaybe<Scalars['String']>;
  userCreditCardId?: InputMaybe<Scalars['Guid']>;
};

export class Input_CreateTransferenceVm {
  description?: InputMaybe<Scalars['String']>;
  value: Scalars['Double'];
};

export class Input_CreateUserDigitalAccountBanksVm {
  account?: InputMaybe<Scalars['String']>;
  accountDigit?: InputMaybe<Scalars['String']>;
  agencyNumber?: InputMaybe<Scalars['String']>;
  bankId?: Scalars['Guid'];
  document?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class Input_ExtractFilterVm {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export class Input_ForgotPasswordVm {
  email?: InputMaybe<Scalars['String']>;
};

export class Input_MySalesFilterVm {
  commision?: InputMaybe<Scalars['Guid']>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class Input_MyStoresFilterVm {
  city?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: Scalars['Int'];
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class Input_ResetPasswordVm {
  password?: InputMaybe<Scalars['String']>;
};

export class Input_StepStore2Vm {
  acceptDeliveryToMatch?: InputMaybe<Scalars['Boolean']>;
  acceptWithdrawal?: Scalars['Boolean'];
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  isDelivery?: Scalars['Boolean'];
  lat?: InputMaybe<Scalars['Double']>;
  lng?: InputMaybe<Scalars['Double']>;
  number?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  referencePoint?: InputMaybe<Scalars['String']>;
  storeDeliveryTaxes?: InputMaybe<Array<InputMaybe<Input_StoreDeliveryTaxCreateVm>>>;
  street?: InputMaybe<Scalars['String']>;
  typeDelivery?: InputMaybe<Scalars['String']>;
  uf?: InputMaybe<Scalars['String']>;
};

export class Input_StoreDeliveryTaxCreateVm {
  fromDistance: Scalars['Double'];
  toDistance: Scalars['Double'];
  value: Scalars['Decimal'];
};

export class Input_UserCreateVm {
  addresses?: InputMaybe<Input_AddressVm>;
  createDigitalAccount?: InputMaybe<Input_CreateDigitalAccountVm>;
  email?: InputMaybe<Scalars['String']>;
  ipOrigin?: InputMaybe<Scalars['String']>;
  isAcceptedTerms?: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  socialFacebook?: InputMaybe<Scalars['String']>;
  socialInstagram?: InputMaybe<Scalars['String']>;
  socialOthers?: InputMaybe<Scalars['String']>;
  socialTikTok?: InputMaybe<Scalars['String']>;
  socialYoutube?: InputMaybe<Scalars['String']>;
};

export class Input_UserCreditCardCreateVm {
  creditCardId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export class Input_UserSuperCreateVm {
  alias?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth: Scalars['DateTime'];
  email?: InputMaybe<Scalars['String']>;
  hideControlBar?: InputMaybe<Scalars['Boolean']>;
  isAcceptedContact?: InputMaybe<Scalars['Boolean']>;
  isAcceptedTerms: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneCountry?: InputMaybe<Scalars['String']>;
  poupExit?: InputMaybe<Scalars['Boolean']>;
  salesIntention?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  storeName?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  trackingId?: InputMaybe<Scalars['Boolean']>;
  video?: InputMaybe<Scalars['Boolean']>;
};

export class Language {
  id: Scalars['Guid'];
  initials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Store>>>;
};

export class Location {
  lat: Scalars['Double'];
  lng: Scalars['Double'];
};

export class MainTextMatchedSubstringVm {
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export class MatchedSubstringVm {
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export class Mutation {
  accessRegister?: Maybe<DefaultMessageVm>;
  addressByCep?: Maybe<ViaCepResponseVm>;
  authenticate?: Maybe<TokenVm>;
  authenticateSuper?: Maybe<TokenSuperUserVm>;
  changePassword?: Maybe<DefaultMessageVm>;
  click?: Maybe<DefaultMessageVm>;
  deleteCreditCard?: Maybe<Scalars['String']>;
  forgotPassword?: Maybe<DefaultMessageVm>;
  forgotPasswordUserSuper?: Maybe<DefaultMessageVm>;
  regiaterStepThree?: Maybe<TokenClientSuperVm>;
  register?: Maybe<UserCreatedResponseVm>;
  registerCreditCard?: Maybe<UserCreditCardVm>;
  registerIuguAccount?: Maybe<DefaultMessageVm>;
  registerStepTwo?: Maybe<StoreVm>;
  registerUserSuper?: Maybe<TokenSuperUserVm>;
  resetPassword?: Maybe<TokenVm>;
  resetPasswordUserSuper?: Maybe<TokenSuperUserVm>;
  transfer?: Maybe<TransactionVm>;
};


export class MutationAccessRegisterArgs {
  input?: InputMaybe<Input_AccessRequestVm>;
};


export class MutationAddressByCepArgs {
  input?: InputMaybe<Scalars['String']>;
};


export class MutationAuthenticateArgs {
  input?: InputMaybe<Input_AuthenticationVm>;
};


export class MutationAuthenticateSuperArgs {
  input?: InputMaybe<Input_AuthenticationVm>;
};


export class MutationChangePasswordArgs {
  model?: InputMaybe<Input_ChangePasswordVm>;
  userId: Scalars['Guid'];
};


export class MutationClickArgs {
  alias?: InputMaybe<Scalars['String']>;
};


export class MutationDeleteCreditCardArgs {
  id: Scalars['Guid'];
};


export class MutationForgotPasswordArgs {
  input?: InputMaybe<Input_ForgotPasswordVm>;
};


export class MutationForgotPasswordUserSuperArgs {
  input?: InputMaybe<Input_ForgotPasswordVm>;
};


export class MutationRegiaterStepThreeArgs {
  input?: InputMaybe<Input_CreateRechargeVm>;
};


export class MutationRegisterArgs {
  input?: InputMaybe<Input_UserCreateVm>;
};


export class MutationRegisterCreditCardArgs {
  input?: InputMaybe<Input_UserCreditCardCreateVm>;
};


export class MutationRegisterStepTwoArgs {
  input?: InputMaybe<Input_StepStore2Vm>;
  storeId: Scalars['Guid'];
};


export class MutationRegisterUserSuperArgs {
  input?: InputMaybe<Input_UserSuperCreateVm>;
};


export class MutationResetPasswordArgs {
  model?: InputMaybe<Input_ResetPasswordVm>;
  userId: Scalars['Guid'];
};


export class MutationResetPasswordUserSuperArgs {
  model?: InputMaybe<Input_ResetPasswordVm>;
  userId: Scalars['Guid'];
};


export class MutationTransferArgs {
  model?: InputMaybe<Input_CreateTransferenceVm>;
};

export class MySalesPaginatorVm {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<MySalesVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class MySalesVm {
  code?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  dateReceipt?: Maybe<Scalars['String']>;
  dateSale?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['String']>;
};

export class MyStoresPaginatorVm {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Maybe<MyStoresVm>>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class MyStoresVm {
  city?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  nameStore?: Maybe<Scalars['String']>;
  periodValidation?: Maybe<Scalars['String']>;
  registerDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  trackId?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
};

export class NortheastVm {
  lat: Scalars['Double'];
  lng: Scalars['Double'];
};

export class PercentMetrics {
  affiliateHitorySales?: Maybe<Array<Maybe<AffiliateHitorySales>>>;
  affiliates?: Maybe<Array<Maybe<Affiliate>>>;
  id: Scalars['Guid'];
  percent?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  quantity: Scalars['Int'];
};

export class PercentMetricsVm {
  id: Scalars['Guid'];
  percent?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  quantity: Scalars['Int'];
};

export class PlaceIdResponseVm {
  html_attributions?: Maybe<Array<Maybe<Scalars['String']>>>;
  result?: Maybe<ResultVm>;
  status?: Maybe<Scalars['String']>;
};

export class PlanRecharge {
  createdAt?: Maybe<Scalars['DateTime']>;
  credit: Scalars['Int'];
  creditPrice: Scalars['Decimal'];
  id: Scalars['Guid'];
  price: Scalars['Decimal'];
  status?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  users?: Maybe<Array<Maybe<User>>>;
};

export class PlanRechargeVm {
  createdAt?: Maybe<Scalars['DateTime']>;
  credit?: Maybe<Scalars['Int']>;
  creditPrice?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
};

export class PlanSuper {
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  order: Scalars['Int'];
  periodOfTime: Scalars['Int'];
  price: Scalars['Decimal'];
  priceTotal: Scalars['Decimal'];
  stores?: Maybe<Array<Maybe<Store>>>;
  type?: Maybe<Scalars['String']>;
};

export class PlanSuperVm {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  order: Scalars['Int'];
  price: Scalars['Decimal'];
  priceTotal: Scalars['Decimal'];
  type?: Maybe<Scalars['String']>;
};

export class Query {
  access?: Maybe<AccessVm>;
  accountInformation?: Maybe<DigitalAccountVm>;
  autocomplete?: Maybe<AutoCompleteResponseVm>;
  balance?: Maybe<DigitalAccountBalanceVm>;
  banks?: Maybe<Array<Maybe<BankVm>>>;
  checkDomain?: Maybe<DefaultMessageVm>;
  checkEmail?: Maybe<DefaultMessageVm>;
  checkEmailUserSuper?: Maybe<DefaultMessageVm>;
  commission?: Maybe<Array<Maybe<CommissionAffiliationVm>>>;
  consolidatedGeneral?: Maybe<Array<Maybe<AffiliateConsolidatedGeneralVm>>>;
  consolidatedRegion?: Maybe<Array<Maybe<AffiliateConsolidatedRegionsInformationVm>>>;
  currentUser?: Maybe<AffiliateVm>;
  currentUserSuper?: Maybe<UserSuperResponseVm>;
  extracts?: Maybe<Array<Maybe<ExtractVm>>>;
  extractsTypes?: Maybe<Array<Maybe<AffiliateExtractsTypesVm>>>;
  listCreditCard?: Maybe<Array<Maybe<UserCreditCardVm>>>;
  listSalesIntention?: Maybe<Array<Maybe<SalesIntentionVm>>>;
  mySales?: Maybe<MySalesPaginatorVm>;
  myStores?: Maybe<MyStoresPaginatorVm>;
  percentMetrics?: Maybe<Array<Maybe<PercentMetricsVm>>>;
  place?: Maybe<PlaceIdResponseVm>;
  planSuper?: Maybe<Array<Maybe<PlanSuperVm>>>;
  resendValidateToken?: Maybe<DefaultMessageVm>;
  validateResetToken?: Maybe<TokenVm>;
  validateResetTokenUserSuper?: Maybe<TokenSuperUserVm>;
  validateToken?: Maybe<TokenVm>;
};


export class QueryAutocompleteArgs {
  input?: InputMaybe<Scalars['String']>;
};


export class QueryCheckDomainArgs {
  subdomain?: InputMaybe<Scalars['String']>;
};


export class QueryCheckEmailArgs {
  email?: InputMaybe<Scalars['String']>;
};


export class QueryCheckEmailUserSuperArgs {
  email?: InputMaybe<Scalars['String']>;
};


export class QueryConsolidatedRegionArgs {
  regionName?: InputMaybe<Scalars['String']>;
};


export class QueryExtractsArgs {
  input?: InputMaybe<Input_ExtractFilterVm>;
};


export class QueryMySalesArgs {
  input?: InputMaybe<Input_MySalesFilterVm>;
};


export class QueryMyStoresArgs {
  input?: InputMaybe<Input_MyStoresFilterVm>;
};


export class QueryPlaceArgs {
  id?: InputMaybe<Scalars['String']>;
};


export class QueryResendValidateTokenArgs {
  userId: Scalars['Guid'];
};


export class QueryValidateResetTokenArgs {
  id: Scalars['Guid'];
};


export class QueryValidateResetTokenUserSuperArgs {
  id: Scalars['Guid'];
};


export class QueryValidateTokenArgs {
  tokenId: Scalars['Guid'];
};

export class ResultVm {
  address_components?: Maybe<Array<Maybe<AddressComponentVm>>>;
  adr_address?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<GeometryVm>;
  icon?: Maybe<Scalars['String']>;
  icon_background_color?: Maybe<Scalars['String']>;
  icon_mask_base_uri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  utc_offset: Scalars['Int'];
  vicinity?: Maybe<Scalars['String']>;
};

export class SalesIntentionVm {
  id: Scalars['Guid'];
  name?: Maybe<Scalars['String']>;
};

export class SecondaryTextMatchedSubstringVm {
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export class SouthwestVm {
  lat: Scalars['Double'];
  lng: Scalars['Double'];
};

export class Store {
  accentColor?: Maybe<Scalars['String']>;
  acceptDeliveryToMatch?: Maybe<Scalars['Boolean']>;
  acceptWithdrawal?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  adultContent?: Maybe<Scalars['Boolean']>;
  affiliate?: Maybe<Affiliate>;
  affiliateHitorySales?: Maybe<Array<Maybe<AffiliateHitorySales>>>;
  affiliateId?: Maybe<Scalars['Guid']>;
  campaingPlanSuperId?: Maybe<Scalars['Guid']>;
  city?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryPhoneCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyId?: Maybe<Scalars['Guid']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailToken?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Scalars['Decimal']>;
  facebookDomainVerification?: Maybe<Scalars['String']>;
  facebookPixel?: Maybe<Scalars['String']>;
  googleAnalytics?: Maybe<Scalars['String']>;
  googleTagManager?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isActive?: Maybe<Scalars['Boolean']>;
  isControlStock?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  isDelivery?: Maybe<Scalars['Boolean']>;
  isDeliveryGlobal: Scalars['Boolean'];
  isDisableVideo?: Maybe<Scalars['Boolean']>;
  isFinished: Scalars['Boolean'];
  isForceClosed: Scalars['Boolean'];
  isMenvi: Scalars['Boolean'];
  isSedex: Scalars['Boolean'];
  isSelling: Scalars['Boolean'];
  isShipping?: Maybe<Scalars['Boolean']>;
  isSuper: Scalars['Boolean'];
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars['Guid']>;
  levelChange: Scalars['Boolean'];
  levelId?: Maybe<Scalars['Guid']>;
  menviConfigured: Scalars['Boolean'];
  minimumOrder?: Maybe<Scalars['Decimal']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  pathIcon?: Maybe<Scalars['String']>;
  pathImage?: Maybe<Scalars['String']>;
  pathLogo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountry?: Maybe<Scalars['String']>;
  phoneToken?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  planSuper?: Maybe<PlanSuper>;
  planSuperId?: Maybe<Scalars['Guid']>;
  postalCode?: Maybe<Scalars['String']>;
  preparationTime?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  pwaId?: Maybe<Scalars['Guid']>;
  referencePoint?: Maybe<Scalars['String']>;
  salesRepresentativeId?: Maybe<Scalars['Guid']>;
  secondaryColor?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  stateInscription?: Maybe<Scalars['String']>;
  storeAcceptedPayments?: Maybe<Array<Maybe<StoreAcceptedPayment>>>;
  storeDeliveryTaxes?: Maybe<Array<Maybe<StoreDeliveryTax>>>;
  street?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  superTillDate?: Maybe<Scalars['DateTime']>;
  trackId?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  typeDelivery?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId: Scalars['Guid'];
  validateEmail: Scalars['Boolean'];
  validatePhone: Scalars['Boolean'];
  validateWhatsapp: Scalars['Boolean'];
  whatsappNumber?: Maybe<Scalars['String']>;
  whatsappNumberCountry?: Maybe<Scalars['String']>;
  whatsappToken?: Maybe<Scalars['String']>;
  wizardStep?: Maybe<Scalars['Int']>;
  wizardStepSuperPedido?: Maybe<Scalars['Int']>;
};

export class StoreAcceptedPayment {
  createdAt: Scalars['DateTime'];
  id: Scalars['Guid'];
  store?: Maybe<Store>;
  storeId: Scalars['Guid'];
  type?: Maybe<Scalars['String']>;
};

export class StoreDeliveryTax {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fromDistance: Scalars['Double'];
  id: Scalars['Guid'];
  isDeleted: Scalars['Boolean'];
  store?: Maybe<Store>;
  storeId: Scalars['Guid'];
  toDistance: Scalars['Double'];
  value: Scalars['Decimal'];
};

export class StoreDomain {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<Scalars['String']>;
  hasDomain: Scalars['Boolean'];
  id: Scalars['Guid'];
  master?: Maybe<Scalars['String']>;
  slave?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId: Scalars['Guid'];
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class StoreOrder {
  createdAt: Scalars['DateTime'];
  deliveryFromDistance?: Maybe<Scalars['Double']>;
  deliveryMethod?: Maybe<Scalars['String']>;
  deliveryTimeEnd?: Maybe<Scalars['String']>;
  deliveryTimeStart?: Maybe<Scalars['String']>;
  deliveryTimeType?: Maybe<Scalars['String']>;
  deliveryToDistance?: Maybe<Scalars['Double']>;
  id: Scalars['Guid'];
  installment?: Maybe<Scalars['Int']>;
  isDelivery: Scalars['Boolean'];
  isDeliveryToMatch?: Maybe<Scalars['Boolean']>;
  isWithdrawal?: Maybe<Scalars['Boolean']>;
  marketpalceValueTax?: Maybe<Scalars['Decimal']>;
  marketplaceTaxFee?: Maybe<Scalars['Decimal']>;
  marketplaceTaxPercent?: Maybe<Scalars['Decimal']>;
  note?: Maybe<Scalars['String']>;
  orderCode?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['DateTime']>;
  orderType?: Maybe<Scalars['String']>;
  reviewComment?: Maybe<Scalars['String']>;
  reviewCommentUser?: Maybe<Scalars['String']>;
  reviewScore?: Maybe<Scalars['Decimal']>;
  reviewScoreUser?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
  step: Scalars['Int'];
  store?: Maybe<Store>;
  storeId: Scalars['Guid'];
  storeOrderPayments?: Maybe<Array<Maybe<StoreOrderPayment>>>;
  taxFee?: Maybe<Scalars['Decimal']>;
  taxPercent?: Maybe<Scalars['Decimal']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userAddress?: Maybe<UserAddress>;
  userAddressId?: Maybe<Scalars['Guid']>;
  userEvaluation: Scalars['Decimal'];
  userId: Scalars['Guid'];
  valueDelivery: Scalars['Decimal'];
  valueOrder: Scalars['Decimal'];
  valueTax?: Maybe<Scalars['Decimal']>;
  valueTotal: Scalars['Decimal'];
};

export class StoreOrderPayment {
  change?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  externalToken?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  storeOrder?: Maybe<StoreOrder>;
  storeOrderId: Scalars['Guid'];
  type?: Maybe<Scalars['String']>;
  userCreditCard?: Maybe<UserCreditCard>;
  userCreditCardId?: Maybe<Scalars['Guid']>;
  value: Scalars['Decimal'];
};

export class StoreVm {
  accentColor?: Maybe<Scalars['String']>;
  acceptDeliveryToMatch?: Maybe<Scalars['Boolean']>;
  acceptWithdrawal?: Maybe<Scalars['Boolean']>;
  access: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  adultContent: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryPhoneCode?: Maybe<Scalars['String']>;
  currencyId: Scalars['Guid'];
  description?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebookDomainVerification?: Maybe<Scalars['String']>;
  facebookPixel?: Maybe<Scalars['String']>;
  googleAnalytics?: Maybe<Scalars['String']>;
  googleTagManager?: Maybe<Scalars['String']>;
  hasSchedule: Scalars['Boolean'];
  id: Scalars['Guid'];
  isActive: Scalars['Boolean'];
  isCatalog: Scalars['Boolean'];
  isControlStock: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isDelivery: Scalars['Boolean'];
  isDeliveryGlobal: Scalars['Boolean'];
  isDigitalAccount?: Maybe<Scalars['String']>;
  isDisableVideo: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  isForceClosed: Scalars['Boolean'];
  isMenvi: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  isSedex: Scalars['Boolean'];
  isSelling: Scalars['Boolean'];
  isSuper: Scalars['Boolean'];
  languageId?: Maybe<Scalars['Guid']>;
  lat: Scalars['Double'];
  lng: Scalars['Double'];
  menviConfigured: Scalars['Boolean'];
  minimumOrder: Scalars['Decimal'];
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  pathIcon?: Maybe<Scalars['String']>;
  pathImage?: Maybe<Scalars['String']>;
  pathLogo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountry?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preparationTime?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  pwa?: Maybe<Scalars['String']>;
  referencePoint?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  secondaryColor?: Maybe<Scalars['String']>;
  socialReason?: Maybe<Scalars['String']>;
  stateInscription?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  typeDelivery?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  validateEmail: Scalars['Boolean'];
  validatePhone: Scalars['Boolean'];
  validateWhatsapp: Scalars['Boolean'];
  whatsappNumber?: Maybe<Scalars['String']>;
  whatsappNumberCountry?: Maybe<Scalars['String']>;
  wizardStep?: Maybe<Scalars['Int']>;
};

export class StructuredFormattingVm {
  main_text?: Maybe<Scalars['String']>;
  main_text_matched_substrings?: Maybe<Array<Maybe<MainTextMatchedSubstringVm>>>;
  secondary_text?: Maybe<Scalars['String']>;
  secondary_text_matched_substrings?: Maybe<Array<Maybe<SecondaryTextMatchedSubstringVm>>>;
};

export class TermVm {
  offset: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export class TokenClientSuperVm {
  authorizationToken?: Maybe<Scalars['String']>;
  barCode?: Maybe<Scalars['String']>;
  billetUrl?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  pixCode?: Maybe<Scalars['String']>;
  pixUrl?: Maybe<Scalars['String']>;
  typePayment?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
};

export class TokenSuperUserVm {
  alias?: Maybe<Scalars['String']>;
  authorizationToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  user?: Maybe<UserSuperResponseVm>;
};

export class TokenVm {
  authorizationToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  user?: Maybe<AffiliateVm>;
};

export class Transaction {
  affiliateHitorySales?: Maybe<Array<Maybe<AffiliateHitorySales>>>;
  affiliateTransactionSupers?: Maybe<Array<Maybe<AffiliateTransactionSuper>>>;
  amount?: Maybe<Scalars['Decimal']>;
  billetBarCode?: Maybe<Scalars['String']>;
  billetUrl?: Maybe<Scalars['String']>;
  chargeAttempts?: Maybe<Scalars['Int']>;
  chargeCode?: Maybe<Scalars['Int']>;
  chargeId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  domain?: Maybe<StoreDomain>;
  domainId?: Maybe<Scalars['Guid']>;
  id: Scalars['Guid'];
  isCheckStockProduct?: Maybe<Scalars['Boolean']>;
  nfCod?: Maybe<Scalars['String']>;
  nfEmission?: Maybe<Scalars['DateTime']>;
  nfNumber?: Maybe<Scalars['Int']>;
  nfSerie?: Maybe<Scalars['Int']>;
  nfStatus?: Maybe<Scalars['String']>;
  nfUrl?: Maybe<Scalars['String']>;
  nfXml?: Maybe<Scalars['String']>;
  order?: Maybe<StoreOrder>;
  orderId?: Maybe<Scalars['Guid']>;
  pixCode?: Maybe<Scalars['String']>;
  pixUrl?: Maybe<Scalars['String']>;
  planRecharge?: Maybe<PlanRecharge>;
  planRechargeId?: Maybe<Scalars['Guid']>;
  planSuperId?: Maybe<Scalars['Guid']>;
  sonId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['Guid']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typePayment?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userCreditCard?: Maybe<UserCreditCard>;
  userCreditCardId?: Maybe<Scalars['Guid']>;
  userId?: Maybe<Scalars['Guid']>;
  validity?: Maybe<Scalars['DateTime']>;
};

export class TransactionAffiliate {
  affiliate?: Maybe<Affiliate>;
  affiliateId: Scalars['Guid'];
  amount?: Maybe<Scalars['Decimal']>;
  chargeAttempts: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Guid'];
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class TransactionVm {
  accountBank?: Maybe<AccountVm>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  type?: Maybe<Scalars['String']>;
  value: Scalars['Double'];
};

export class User {
  brand?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  hideControlBar?: Maybe<Scalars['Boolean']>;
  id: Scalars['Guid'];
  isAcceptedContact?: Maybe<Scalars['Boolean']>;
  isAcceptedTerms?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  isDigitalAccount?: Maybe<Scalars['String']>;
  isFinishedRegisterSuperPedido?: Maybe<Scalars['Boolean']>;
  isRegisterSuperPedido?: Maybe<Scalars['Boolean']>;
  isValidated?: Maybe<Scalars['Boolean']>;
  iuguToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  planRecharge?: Maybe<PlanRecharge>;
  planRechargeId?: Maybe<Scalars['Guid']>;
  poupExit?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['String']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  resetPasswordToken?: Maybe<Scalars['Guid']>;
  salesIntention?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Store>>>;
  trackingId?: Maybe<Scalars['Boolean']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAddresses?: Maybe<Array<Maybe<UserAddress>>>;
  userCreditCard?: Maybe<UserCreditCard>;
  userCreditCardId?: Maybe<Scalars['Guid']>;
  userCreditCards?: Maybe<Array<Maybe<UserCreditCard>>>;
  validateToken?: Maybe<Scalars['Guid']>;
  video?: Maybe<Scalars['Boolean']>;
};

export class UserAddress {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isChargeAddress: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  referencePoint?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId: Scalars['Guid'];
};

export class UserCreatedResponseVm {
  authorizationToken?: Maybe<Scalars['String']>;
};

export class UserCreditCard {
  brand?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCardId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDeleted: Scalars['Boolean'];
  last4CardNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  storeOrderPayments?: Maybe<Array<Maybe<StoreOrderPayment>>>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  user?: Maybe<User>;
  userId: Scalars['Guid'];
  users?: Maybe<Array<Maybe<User>>>;
};

export class UserCreditCardVm {
  brand?: Maybe<Scalars['String']>;
  creditCardId?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  last4CardNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export class UserSuperResponseVm {
  alias?: Maybe<Scalars['String']>;
  automaticRechargeValue?: Maybe<Scalars['Decimal']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Guid'];
  isDigitalAccount?: Maybe<Scalars['String']>;
  isOwner: Scalars['Boolean'];
  isValidated: Scalars['Boolean'];
  iugutoken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pathImage?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountry?: Maybe<Scalars['String']>;
  planRechargeId?: Maybe<Scalars['Guid']>;
  salesIntention?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  store?: Maybe<Store>;
  typePayment?: Maybe<Scalars['String']>;
  typeRecharge?: Maybe<Scalars['String']>;
  userCreditCardId?: Maybe<Scalars['Guid']>;
};

export class ViaCepResponseVm {
  bairro?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  complemento?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  gia?: Maybe<Scalars['String']>;
  ibge?: Maybe<Scalars['String']>;
  localidade?: Maybe<Scalars['String']>;
  logradouro?: Maybe<Scalars['String']>;
  siafi?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
};

export class ViewportVm {
  northeast?: Maybe<NortheastVm>;
  southwest?: Maybe<SouthwestVm>;
};

export type UserCreditCardVmFragment = { brand?: string | null, creditCardId?: string | null, expirationMonth?: string | null, expirationYear?: string | null, id: any, last4CardNumber?: string | null, name?: string | null };

export type TokenClientSuperVmFragment = { authorizationToken?: string | null, barCode?: string | null, brand?: string | null, holder?: string | null, number?: string | null, pixCode?: string | null, typePayment?: string | null, validity?: string | null, pixUrl?: string | null, billetUrl?: string | null };

export type StoreVmFragment = { id: any };

export type StoreFragment = { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null };

export type PlaceIdResponseVmFragment = { html_attributions?: Array<string | null> | null, status?: string | null, result?: { formatted_address?: string | null, place_id?: string | null, address_components?: Array<{ short_name?: string | null, long_name?: string | null, types?: Array<string | null> | null } | null> | null, geometry?: { location?: { lat: any, lng: any } | null } | null } | null };

export type AutoCompleteResponseVmFragment = { predictions?: Array<{ description?: string | null, place_id?: string | null, reference?: string | null, types?: Array<string | null> | null, structured_formatting?: { main_text?: string | null, secondary_text?: string | null } | null } | null> | null };

export type AutoCompleteResponsePredictionsVmFragment = { description?: string | null, place_id?: string | null, reference?: string | null, types?: Array<string | null> | null, structured_formatting?: { main_text?: string | null, secondary_text?: string | null } | null };

export type UserSuperResponseVmFragment = { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null };

export type DefaultMessageVmFragment = { code: number, id?: any | null, message?: string | null, url?: string | null };

export type TokenSuperUserVmFragment = { authorizationToken?: string | null, storeId?: string | null, user?: { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null } | null };

export type RegisterCreditCardMutationVariables = Exact<{
  input?: InputMaybe<Input_UserCreditCardCreateVm>;
}>;


export type RegisterCreditCardMutation = { registerCreditCard?: { brand?: string | null, creditCardId?: string | null, expirationMonth?: string | null, expirationYear?: string | null, id: any, last4CardNumber?: string | null, name?: string | null } | null };

export type ListCreditCardQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCreditCardQuery = { listCreditCard?: Array<{ brand?: string | null, creditCardId?: string | null, expirationMonth?: string | null, expirationYear?: string | null, id: any, last4CardNumber?: string | null, name?: string | null } | null> | null };

export type DeleteCreditCardMutationVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type DeleteCreditCardMutation = { deleteCreditCard?: string | null };

export type AuthenticateSuperMutationVariables = Exact<{
  input: Input_AuthenticationVm;
}>;


export type AuthenticateSuperMutation = { authenticateSuper?: { authorizationToken?: string | null, storeId?: string | null, user?: { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null } | null } | null };

export type AutocompleteQueryVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type AutocompleteQuery = { autocomplete?: { predictions?: Array<{ description?: string | null, place_id?: string | null, reference?: string | null, types?: Array<string | null> | null, structured_formatting?: { main_text?: string | null, secondary_text?: string | null } | null } | null> | null } | null };

export type PlaceQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type PlaceQuery = { place?: { html_attributions?: Array<string | null> | null, status?: string | null, result?: { formatted_address?: string | null, place_id?: string | null, address_components?: Array<{ short_name?: string | null, long_name?: string | null, types?: Array<string | null> | null } | null> | null, geometry?: { location?: { lat: any, lng: any } | null } | null } | null } | null };

export type ListSalesIntentionQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSalesIntentionQuery = { listSalesIntention?: Array<{ id: any, name?: string | null } | null> | null };

export type ClickMutationVariables = Exact<{
  alias?: InputMaybe<Scalars['String']>;
}>;


export type ClickMutation = { click?: { id?: any | null, message?: string | null, code: number, url?: string | null } | null };

export type AccessRegisterMutationVariables = Exact<{
  input?: InputMaybe<Input_AccessRequestVm>;
}>;


export type AccessRegisterMutation = { accessRegister?: { id?: any | null, message?: string | null, code: number, url?: string | null } | null };

export type PlanSuperQueryVariables = Exact<{ [key: string]: never; }>;


export type PlanSuperQuery = { planSuper?: Array<{ description?: string | null, id: any, order: number, price: any, priceTotal: any, type?: string | null } | null> | null };

export type ForgotPasswordUserSuperMutationVariables = Exact<{
  input: Input_ForgotPasswordVm;
}>;


export type ForgotPasswordUserSuperMutation = { forgotPasswordUserSuper?: { code: number, id?: any | null, message?: string | null, url?: string | null } | null };

export type CurrentUserSuperQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserSuperQuery = { currentUserSuper?: { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null } | null };

export type ValidateResetTokenUserSuperQueryVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type ValidateResetTokenUserSuperQuery = { validateResetTokenUserSuper?: { authorizationToken?: string | null, storeId?: string | null, user?: { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null } | null } | null };

export type RegisterUserSuperMutationVariables = Exact<{
  input?: InputMaybe<Input_UserSuperCreateVm>;
}>;


export type RegisterUserSuperMutation = { registerUserSuper?: { authorizationToken?: string | null, storeId?: string | null, user?: { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null } | null } | null };

export type RegisterStepTwoMutationVariables = Exact<{
  input?: InputMaybe<Input_StepStore2Vm>;
  storeId: Scalars['Guid'];
}>;


export type RegisterStepTwoMutation = { registerStepTwo?: { id: any } | null };

export type RegisterStepThreeMutationVariables = Exact<{
  input?: InputMaybe<Input_CreateRechargeVm>;
}>;


export type RegisterStepThreeMutation = { regiaterStepThree?: { authorizationToken?: string | null, barCode?: string | null, brand?: string | null, holder?: string | null, number?: string | null, pixCode?: string | null, typePayment?: string | null, validity?: string | null, pixUrl?: string | null, billetUrl?: string | null } | null };

export type CheckEmailQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
}>;


export type CheckEmailQuery = { checkEmailUserSuper?: { id?: any | null, message?: string | null, code: number, url?: string | null } | null };

export type CheckDomainQueryVariables = Exact<{
  subdomain?: InputMaybe<Scalars['String']>;
}>;


export type CheckDomainQuery = { checkDomain?: { id?: any | null, message?: string | null, code: number, url?: string | null } | null };

export type ResetPasswordUserSuperMutationVariables = Exact<{
  userId: Scalars['Guid'];
  model: Input_ResetPasswordVm;
}>;


export type ResetPasswordUserSuperMutation = { resetPasswordUserSuper?: { authorizationToken?: string | null, storeId?: string | null, user?: { automaticRechargeValue?: any | null, dateOfBirth?: any | null, alias?: string | null, document?: string | null, email?: string | null, id: any, isDigitalAccount?: string | null, isOwner: boolean, isValidated: boolean, iugutoken?: string | null, name?: string | null, pathImage?: string | null, phone?: string | null, phoneCountry?: string | null, planRechargeId?: any | null, salesIntention?: string | null, score?: number | null, sex?: string | null, typePayment?: string | null, typeRecharge?: string | null, userCreditCardId?: any | null, store?: { wizardStepSuperPedido?: number | null, id: any, subdomain?: string | null } | null } | null } | null };

export const UserCreditCardVmFragmentDoc = gql`
    fragment UserCreditCardVm on UserCreditCardVm {
  brand
  creditCardId
  expirationMonth
  expirationYear
  id
  last4CardNumber
  name
}
    `;
export const TokenClientSuperVmFragmentDoc = gql`
    fragment TokenClientSuperVM on TokenClientSuperVM {
  authorizationToken
  barCode
  brand
  holder
  number
  pixCode
  typePayment
  validity
  pixUrl
  billetUrl
}
    `;
export const StoreVmFragmentDoc = gql`
    fragment StoreVm on StoreVm {
  id
}
    `;
export const PlaceIdResponseVmFragmentDoc = gql`
    fragment PlaceIdResponseVm on PlaceIdResponseVm {
  html_attributions
  result {
    formatted_address
    place_id
    address_components {
      short_name
      long_name
      types
    }
    geometry {
      location {
        lat
        lng
      }
    }
  }
  status
}
    `;
export const AutoCompleteResponsePredictionsVmFragmentDoc = gql`
    fragment AutoCompleteResponsePredictionsVm on AutoCompleteResponsePredictionsVm {
  description
  place_id
  reference
  structured_formatting {
    main_text
    secondary_text
  }
  types
}
    `;
export const AutoCompleteResponseVmFragmentDoc = gql`
    fragment AutoCompleteResponseVm on AutoCompleteResponseVm {
  predictions {
    ...AutoCompleteResponsePredictionsVm
  }
}
    ${AutoCompleteResponsePredictionsVmFragmentDoc}`;
export const DefaultMessageVmFragmentDoc = gql`
    fragment DefaultMessageVm on DefaultMessageVm {
  code
  id
  message
  url
}
    `;
export const StoreFragmentDoc = gql`
    fragment Store on Store {
  wizardStepSuperPedido
  id
  subdomain
}
    `;
export const UserSuperResponseVmFragmentDoc = gql`
    fragment UserSuperResponseVM on UserSuperResponseVM {
  automaticRechargeValue
  dateOfBirth
  alias
  document
  email
  id
  isDigitalAccount
  isOwner
  isValidated
  iugutoken
  name
  pathImage
  phone
  phoneCountry
  planRechargeId
  salesIntention
  score
  sex
  store {
    ...Store
  }
  typePayment
  typeRecharge
  userCreditCardId
}
    ${StoreFragmentDoc}`;
export const TokenSuperUserVmFragmentDoc = gql`
    fragment TokenSuperUserVm on TokenSuperUserVm {
  authorizationToken
  storeId
  user {
    ...UserSuperResponseVM
  }
}
    ${UserSuperResponseVmFragmentDoc}`;
export const RegisterCreditCardDocument = gql`
    mutation RegisterCreditCard($input: Input_UserCreditCardCreateVm) {
  registerCreditCard(input: $input) {
    ...UserCreditCardVm
  }
}
    ${UserCreditCardVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterCreditCardGQL extends Apollo.Mutation<RegisterCreditCardMutation, RegisterCreditCardMutationVariables> {
    document = RegisterCreditCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCreditCardDocument = gql`
    query ListCreditCard {
  listCreditCard {
    ...UserCreditCardVm
  }
}
    ${UserCreditCardVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCreditCardGQL extends Apollo.Query<ListCreditCardQuery, ListCreditCardQueryVariables> {
    document = ListCreditCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCreditCardDocument = gql`
    mutation DeleteCreditCard($id: Guid!) {
  deleteCreditCard(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCreditCardGQL extends Apollo.Mutation<DeleteCreditCardMutation, DeleteCreditCardMutationVariables> {
    document = DeleteCreditCardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthenticateSuperDocument = gql`
    mutation authenticateSuper($input: Input_AuthenticationVm!) {
  authenticateSuper(input: $input) {
    ...TokenSuperUserVm
  }
}
    ${TokenSuperUserVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthenticateSuperGQL extends Apollo.Mutation<AuthenticateSuperMutation, AuthenticateSuperMutationVariables> {
    document = AuthenticateSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AutocompleteDocument = gql`
    query Autocomplete($input: String) {
  autocomplete(input: $input) {
    ...AutoCompleteResponseVm
  }
}
    ${AutoCompleteResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AutocompleteGQL extends Apollo.Query<AutocompleteQuery, AutocompleteQueryVariables> {
    document = AutocompleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PlaceDocument = gql`
    query Place($id: String) {
  place(id: $id) {
    ...PlaceIdResponseVm
  }
}
    ${PlaceIdResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PlaceGQL extends Apollo.Query<PlaceQuery, PlaceQueryVariables> {
    document = PlaceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSalesIntentionDocument = gql`
    query ListSalesIntention {
  listSalesIntention {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSalesIntentionGQL extends Apollo.Query<ListSalesIntentionQuery, ListSalesIntentionQueryVariables> {
    document = ListSalesIntentionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClickDocument = gql`
    mutation Click($alias: String) {
  click(alias: $alias) {
    id
    message
    code
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClickGQL extends Apollo.Mutation<ClickMutation, ClickMutationVariables> {
    document = ClickDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccessRegisterDocument = gql`
    mutation AccessRegister($input: Input_AccessRequestVM) {
  accessRegister(input: $input) {
    id
    message
    code
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccessRegisterGQL extends Apollo.Mutation<AccessRegisterMutation, AccessRegisterMutationVariables> {
    document = AccessRegisterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PlanSuperDocument = gql`
    query PlanSuper {
  planSuper {
    description
    id
    order
    price
    priceTotal
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlanSuperGQL extends Apollo.Query<PlanSuperQuery, PlanSuperQueryVariables> {
    document = PlanSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordUserSuperDocument = gql`
    mutation forgotPasswordUserSuper($input: Input_ForgotPasswordVm!) {
  forgotPasswordUserSuper(input: $input) {
    ...DefaultMessageVm
  }
}
    ${DefaultMessageVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordUserSuperGQL extends Apollo.Mutation<ForgotPasswordUserSuperMutation, ForgotPasswordUserSuperMutationVariables> {
    document = ForgotPasswordUserSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserSuperDocument = gql`
    query CurrentUserSuper {
  currentUserSuper {
    ...UserSuperResponseVM
  }
}
    ${UserSuperResponseVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserSuperGQL extends Apollo.Query<CurrentUserSuperQuery, CurrentUserSuperQueryVariables> {
    document = CurrentUserSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateResetTokenUserSuperDocument = gql`
    query validateResetTokenUserSuper($id: Guid!) {
  validateResetTokenUserSuper(id: $id) {
    ...TokenSuperUserVm
  }
}
    ${TokenSuperUserVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateResetTokenUserSuperGQL extends Apollo.Query<ValidateResetTokenUserSuperQuery, ValidateResetTokenUserSuperQueryVariables> {
    document = ValidateResetTokenUserSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterUserSuperDocument = gql`
    mutation RegisterUserSuper($input: Input_UserSuperCreateVM) {
  registerUserSuper(input: $input) {
    ...TokenSuperUserVm
  }
}
    ${TokenSuperUserVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterUserSuperGQL extends Apollo.Mutation<RegisterUserSuperMutation, RegisterUserSuperMutationVariables> {
    document = RegisterUserSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterStepTwoDocument = gql`
    mutation RegisterStepTwo($input: Input_StepStore2Vm, $storeId: Guid!) {
  registerStepTwo(input: $input, storeId: $storeId) {
    ...StoreVm
  }
}
    ${StoreVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterStepTwoGQL extends Apollo.Mutation<RegisterStepTwoMutation, RegisterStepTwoMutationVariables> {
    document = RegisterStepTwoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterStepThreeDocument = gql`
    mutation RegisterStepThree($input: Input_CreateRechargeVm) {
  regiaterStepThree(input: $input) {
    ...TokenClientSuperVM
  }
}
    ${TokenClientSuperVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterStepThreeGQL extends Apollo.Mutation<RegisterStepThreeMutation, RegisterStepThreeMutationVariables> {
    document = RegisterStepThreeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckEmailDocument = gql`
    query CheckEmail($email: String) {
  checkEmailUserSuper(email: $email) {
    id
    message
    code
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckEmailGQL extends Apollo.Query<CheckEmailQuery, CheckEmailQueryVariables> {
    document = CheckEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckDomainDocument = gql`
    query CheckDomain($subdomain: String) {
  checkDomain(subdomain: $subdomain) {
    id
    message
    code
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckDomainGQL extends Apollo.Query<CheckDomainQuery, CheckDomainQueryVariables> {
    document = CheckDomainDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordUserSuperDocument = gql`
    mutation resetPasswordUserSuper($userId: Guid!, $model: Input_ResetPasswordVm!) {
  resetPasswordUserSuper(model: $model, userId: $userId) {
    ...TokenSuperUserVm
  }
}
    ${TokenSuperUserVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordUserSuperGQL extends Apollo.Mutation<ResetPasswordUserSuperMutation, ResetPasswordUserSuperMutationVariables> {
    document = ResetPasswordUserSuperDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }