import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';

// Extra Config
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

const routes: Routes = [
  {path: 'landing', loadChildren: () => import('./core/landing/landing.module').then(m => m.LandingModule)},
  {path: 'register', loadChildren: () => import('./core/register/register.module').then(m => m.RegisterModule)},
  {path: 'step', loadChildren: () => import('./layout/main/main.module').then(m => m.MainModule)},
  {path: 'esqueci-minha-senha/:id', loadChildren: () => import('./modules/recovery/recovery.module').then(m => m.RecoveryModule)},
  {path: '', redirectTo: '/landing', pathMatch: 'full'},
  {path: '**', loadChildren: () => import('./core/landing/landing.module').then(m => m.LandingModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
