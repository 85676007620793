import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {catchError, finalize, Observable, throwError} from "rxjs";
import {AuthUtils} from "./auth.utils";

declare var $: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public requests = 0;
  public arrNoLoader = [
    '.svg',
    '/assets',
    '/svg',
    'Autocomplete',
    'CheckEmail',
    'CheckDomain',
  ];

  constructor(private _authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();
    let countLoaderBlock = 0;
    let newHeaders = req.headers;
    const culture = localStorage.getItem('culture') as string;

    // if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
    //   newHeaders = req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken);
    // }
    // if (culture != null) {
    //   newHeaders = req.headers.set('Culture', 'Bearer ' + culture);
    // }

    if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
      newReq = req.clone({
        headers: newHeaders.set('Authorization', 'Bearer ' + this._authService.accessToken)
      });
    }

    if (req.body != null && req.body.operationName != null) {
      // console.log(req.body.operationName);
      this.arrNoLoader.map((e, i) => {
        if (req.body.operationName.indexOf(e) >= 0) {
          countLoaderBlock++;
        }
      });
    }

    if (req.body != null && req.body.operationName != null) {
      // console.log(req.body.operationName);
      this.arrNoLoader.map((e, i) => {
        if (req.body.operationName.indexOf(e) >= 0) {
          countLoaderBlock++;
        }
      });
    }
    if (countLoaderBlock === 0) {
      this.toggleLoader(true);
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {

        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          this._authService.signOut();

          // Reload the app
          location.reload();
        }

        return throwError(error);
      }),
      finalize(() => {
          this.requests = this.requests - 1;
          if (this.requests === 0 || this.requests < 0) {
            this.toggleLoader(false);
          }
        }
      ));
    ///////////////////////////////////


  }

  toggleLoader(b: boolean): void {
    if (!b) {
      $('#loaderBox').fadeOut();
    } else {
      $('#loaderBox').fadeIn();
    }
  }
}
